import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import { useAuth } from '../../../context/useAuth';
import { Section, Main } from '../../../components/content';
import { SERVER_URL } from '../../../config/index';
import { AXIOS_API_CALL } from '../../../utils/endpoint';
import Table from './Table';
import Loading from '../../../components/loading/Loading';
import SelectCustom from '../../../components/customSelect/CustomSelect';
import { Link, useLocation } from 'react-router-dom';
import { PERMISSIONS } from '../../../utils/permissions';
import { getGroceryPermissions } from '../../../middleware';
import LoadingPlaceholder from '../../../components/loadingPlaceholder/LoadingPlaceholder';
import Modal from '../../../components/modal/Modal';
import { Radio, Input, notification, DatePicker, Space } from 'antd';

const options = [5, 10, 20, 50, 100];
const statuses = ['Ordered', 'In Progress', 'On Delivery', 'Delivered', 'Canceled'];

const Orders = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [groceryPermissions, setGroceryPermissions] = useState([]);

  // GET USER TOKEN
  useEffect(() => {
    const { token } = user;
    if (user && token) {
      const decodeToken = jwt_decode(token);
      const permissions = decodeToken.roleData?.permissions;

      if (location.pathname.includes(`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}`)) {
        if (Object.keys(permissions).some((permission) => permission.includes(PERMISSIONS.grocery))) {
          setGroceryPermissions(permissions.grocery);
        }
      }
    }
  }, [location, user]);

  // LOADER PROTECTION
  const [updateStatusBtnLoader, setUpdateStatusBtnLoader] = useState(false);

  // SEARCH
  const [search, setSearch] = useState(null);
  const [searchForm, setSearchForm] = useState({ query: '' });
  const [filterStatus, setFilterStatus] = useState('');

  const handleOnSubmitSearch = (e) => {
    e.preventDefault();
    setSearch(searchForm.query);
    setReadDataRefetch((prevState) => !prevState);
  };

  const handleOnChangeSearch = (value) => {
    setSearchForm({ query: value });
  };

  const handleOnClearSearch = () => {
    setSearch(null);
    setSearchForm({ query: '' });
    setReadDataRefetch((prevState) => !prevState);
  };

  // BULK CHECKBOX
  const [bulkOrders, setBulkOrders] = useState([])
  const [bulkPdfActive, setBulkPdfActive] = useState(false)
  // BULK FUNCTION
  const handleSetBulkOrders = (id, name) => {
    switch(name){
      case 'single':
        if (bulkOrders && bulkOrders.length > 0 && bulkOrders.includes(id)) {
          const filteredArray = bulkOrders.filter(i => i !== id)
          setBulkOrders(filteredArray)
          return
        }
        setBulkOrders(prev => {
          return [
            ...prev,
            id
          ]
        })
        break;
      case 'bulk':
        const bulkIds = bulkOrders && bulkOrders.length <= 0 && readData.data?.Data && readData.data?.Data.length > 0 ? readData.data?.Data.map(i => i._id) : []
        setBulkOrders(bulkIds)
        break
      default: 
        return
    }
  }
  // PAGE
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPage, setLimitPage] = useState(10);

  // FILTER STATUS
  const handleStatusFilter = (value) => {
    setFilterStatus(value);
    setReadDataRefetch((prevState) => !prevState);
  };

  // READ
  const [readData, setReadData] = useState([]);
  const [readDataRefetch, setReadDataRefetch] = useState(false);
  const [readDataLoading, setReadDataLoading] = useState(true);

  const getReadData = useCallback(() => {
    const { token } = user;

    try {
      const response = axios
        .post(`${SERVER_URL}/${AXIOS_API_CALL.orders}/${currentPage}/${limitPage}/${search || null}/?status=${filterStatus}`, null, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
        .then((res) => {
          setTimeout(() => {
            setReadDataLoading(false);
          }, 200);
          return res;
        })
        .catch((err) => {
          setReadDataLoading(false);
          console.error(err);
        });

      return response;
    } catch (err) {}
  }, [user, limitPage, search, currentPage, filterStatus]);

  useEffect(() => {
    let isMounted = true;

    new Promise((resolve, reject) => {
      setReadDataLoading(true);
      setTimeout(() => {
        resolve(getReadData());
      }, 700);
    }).then((res) => {
      if (isMounted) {
        setReadData(res?.data);
      }
    });

    return () => {
      isMounted = false;
    };
  }, [getReadData, readDataRefetch]);

  useEffect(() => {
    if (!!search) {
      setCurrentPage(1);
    }
  }, [search, setCurrentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [limitPage, setCurrentPage]);

  // RESET BULK CHECKBOX IF ANOTHER PAGE
  useEffect(() => {
    setBulkOrders([])
  },[currentPage, limitPage])

  // For PDF button
  const [disablePdfButton, setDisablePdfButton] = useState(false)

  // GENERATE PDF RECEIPT
    const handlePrintOrderReceipt = async (orderId) => {
      const { token } = user
      // Prevent user for clicking multiple times before the pdf is created
      setDisablePdfButton(true)
      try {
        const payload = {
          orderIds: [orderId]
        }
        const response = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.orders}/${AXIOS_API_CALL.generatePdf}`, payload, {
          withCredentials: false,
          headers: { Authorization: `Bearer ${token}` }
        })
  
        if (response && response.status === 201) {
          const buffers_response = response.data.pdfBuffers
          for (let i = 0; i < buffers_response.length; i++) {
            const buffer_array = buffers_response[i].data.data
            const buffer = new Uint8Array(buffer_array)
            const blob = new Blob([buffer], { type: 'application/pdf' })
            const link = window.URL.createObjectURL(blob)
            window.open(link, '_blank')
          }
          // Turn off the overlay after all pdfs are generated
          setDisablePdfButton(false)
        }
      } catch (error) {
        // Enable user to click again
        setDisablePdfButton(false)
        console.error(error)
      }
    }

  // PAGINATION
  const paginationProps = {
    current: currentPage,
    setCurrentPage: setCurrentPage,
    limitPage: limitPage,
    buttonLimit: 3,
    pagesCount: readData.data?.pagesCount,
    totalCount: readData.data?.totalCount
  };

  // FILTER PROPS
  const tableFiltersProps = {
    handleStatusFilter: handleStatusFilter
  };

  // RESCHEDULE ORDER
  const rescheduledModalFormRef = useRef();
  const [toggleRescheduled, setToggleRescheduled] = useState(false);
  const [rescheduledOrder, setRescheduledOrder] = useState({ id: '', rescheduled: '', status: '', moment: '' });

  //DELETE-RESCHEDULED
  const deleteRescheduledModalFormRef = useRef();
  const [toggleDeleteRescheduled, setToggleDeleteRescheduled] = useState(false);
  // const [deleteRescheduledOrderId, setDeleteRescheduledOrderId] = useState('');

  // DELETE-RESCHEDULE PROPS
  const deleteRescheduledProps = {
    active: toggleDeleteRescheduled,
    onToggle: setToggleDeleteRescheduled,
    formRef: deleteRescheduledModalFormRef,
    onSetDeleteReschedule: setRescheduledOrder,
    getDeleteReschedule: rescheduledOrder
  };

  const onSelectDate = (moment, stringTime, id, status) => {
    setRescheduledOrder({ id: id, rescheduled: stringTime, status: status, moment: moment });
  };
  // RESCHEDULE PROPS
  const rescheduledProps = {
    active: toggleRescheduled,
    onToggle: setToggleRescheduled,
    formRef: rescheduledModalFormRef,
    onSetReschedule: setRescheduledOrder,
    getReschedule: rescheduledOrder
  };

  // UPDATE STATUS
  const updateModalFormRef = useRef();
  const [toggleUpdate, setToggleUpdate] = useState(false);
  const [updateId, setUpdateId] = useState({ id: '', status: '', canceled_reason: '', rescheduled: '' });

  // UPDATE PROPS
  const updateProps = {
    active: toggleUpdate,
    onToggle: setToggleUpdate,
    formRef: updateModalFormRef,
    onSetId: setUpdateId,
    getId: updateId
  };

  // TABLE ACTIONS PROPS
  const tableActionsProps = {
    handleToggle: handleToggle,
    updateActions: updateProps,
    rescheduledActions: rescheduledProps,
    deleteRescheduledActions: deleteRescheduledProps,
    location: location,
    groceryPermissions: groceryPermissions,
    getGroceryPermissions: getGroceryPermissions,
    handlePrintOrderReceipt: handlePrintOrderReceipt,
    disablePdfButton: disablePdfButton,
    handleSetBulkOrders,
    bulkOrders,
  };

  function handleToggle(props) {
    const { name, state, data } = props;
    switch (name) {
      case 'update':
        state.onToggle(true);
        state.onSetId({ id: data.id, status: data.status, canceled_reason: data.canceled_reason, rescheduled: data.rescheduled });
        break;
      case 'rescheduled':
        state.onToggle(true);
        state.onSetReschedule({ id: data.id, status: data.status });
        break;
      case 'delete-rescheduled':
        state.onToggle(true);
        state.onSetDeleteReschedule({ id: data.id, rescheduled: '', status: data.status });
        //
        break;
      default:
        console.warn('Default of: handleToggle function');
    }
  }

  // STATUS
  const [selectedStatus, setSelectedStatus] = useState('');
  const [cancelationReason, setCancelationReason] = useState('');
  let radioArray = [];
  if (updateId.status !== '') {
    radioArray = statuses.filter((item) => item !== updateId.status);
  }
  const onStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setCancelationReason('');
  };
  async function handleOnSubmit({ event }) {
    event.preventDefault();
    const { token } = user;
    let updatePayload = {
      status: selectedStatus,
      canceled_reason: cancelationReason,
      rescheduled: updateId.rescheduled
    };
    try {
      if (selectedStatus === 'Canceled' && cancelationReason === '') {
        notification.error({
          message: 'Please enter a reason for order cancelation.',
          placement: 'bottomLeft'
        });
      } else if (selectedStatus === '') {
        notification.error({
          message: 'Please select a status or click cancel.',
          placement: 'bottomLeft'
        });
      } else {
        setUpdateStatusBtnLoader(true);
        axios
          .put(`${SERVER_URL}/${AXIOS_API_CALL.orders}/${AXIOS_API_CALL.updateOrder}/${updateId.id}`, { ...updatePayload }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
          .then((res) => {
            if (res.status === 200) {
              setToggleUpdate(false);
              setReadDataRefetch((prevState) => !prevState);
              notification.success({
                message: res.data.message,
                placement: 'bottomLeft'
              });
            }
            setTimeout(() => {
              setUpdateStatusBtnLoader(false);
            }, 400);
          })
          .catch((err) => console.error(err))
          .finally(setTimeout(() => {}, 700));
      }
    } catch (err) {}
  }
  async function handleOnSubmitRescheduled({ event }) {
    event.preventDefault();
    const { token } = user;
    try {
      setUpdateStatusBtnLoader(true);
      axios
        .put(`${SERVER_URL}/${AXIOS_API_CALL.orders}/${AXIOS_API_CALL.updateOrder}/${rescheduledOrder.id}`, { status: rescheduledOrder.status, rescheduled: rescheduledOrder.rescheduled === '' ? '' : rescheduledOrder.rescheduled }, { withCredentials: false, headers: { department: PERMISSIONS.grocery, Authorization: `Bearer ${token}` } })
        .then((res) => {
          if (res.status === 200) {
            setToggleRescheduled(false);
            setToggleDeleteRescheduled(false);
            setReadDataRefetch((prevState) => !prevState);
            notification.success({
              message: res.data.message,
              placement: 'bottomLeft'
            });
          }
          setTimeout(() => {
            setUpdateStatusBtnLoader(false);
          }, 400);
        })
        .catch((err) => console.error(err))
        .finally(setTimeout(() => {}, 700));
    } catch (err) {
      console.error('error', err);
    }
  }

  useEffect(() => {
    if (toggleUpdate === false) {
      setSelectedStatus('');
      setCancelationReason('');
    }
  }, [toggleUpdate]);

  const handleBulkPrintReceipts = async () => {
    const { token } = user
    // Turn on the overlay then it starts generating pdfs
    setBulkPdfActive(true)
    try {
      const payload = {
        orderIds: bulkOrders
      }
      const response = await axios.post(`${SERVER_URL}/${AXIOS_API_CALL.orders}/${AXIOS_API_CALL.generatePdf}`, payload, {
        withCredentials: false,
        headers: { Authorization: `Bearer ${token}` }
      })
      if (response && response.status === 201) {
        const buffers_response = response.data.pdfBuffers
        const pop_ups_local = window.localStorage.getItem('pop_ups_enabled')
        if (!pop_ups_local && confirm("You need to enable pop ups in the browser settings and try again.")) {
          window.localStorage.setItem('pop_ups_enabled', 'true')
        }

        let isPopupBlocked = false;
        const testPopup = window.open('', '_blank');
        if (!testPopup) {
            isPopupBlocked = true;
        } else {
            testPopup.close();
        }

        if (!isPopupBlocked) {
          // If popups are allowed, proceed to open the PDFs
          for (let i = 0; i < buffers_response.length; i++) {
            const buffer_array = buffers_response[i].data.data;
            const buffer = new Uint8Array(buffer_array);
            const blob = new Blob([buffer], { type: 'application/pdf' });
            const link = window.URL.createObjectURL(blob);

            const newTab = window.open(link, '_blank');
      
            newTab.onload = () => {
                newTab.print();
            };
          }
      }
        // Turn off the overlay after all pdfs are generated
        setBulkPdfActive(false)
      }
    } catch (error) {
      // Turn off the overlay if error occures
      setBulkPdfActive(false)
      console.error(error)
    }
  }

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current - 1 < Date.now();
  }

  const activeClass = window.location.href.includes('orders') ? 'active' : ''

  return (
    <>
      <div className='orders__navigation'>
        <Link className={`page-title ${activeClass}`} to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.orders}`}>
          Orders
        </Link>
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.createOrders) && (
          <Link className={`page-title`} to={`/${PERMISSIONS.dashboard}/${PERMISSIONS.grocery}/${PERMISSIONS.createOrder}`}>
            Create Order
          </Link>
        )}
      </div>
      <Section className="section__wrapper section__orders">
        <header className="section__header">
          <div className="title">Order</div>
          {!readDataLoading ? (
            <div className="filter">
              <span style={{ fontSize: '18px', lineHeight: '24px', color: 'rgb(99, 99, 99)' }}>Show: </span>
              <SelectCustom options={options} label={'Orders'} limit={limitPage} setLimit={setLimitPage} setRefetch={setReadDataRefetch} loading={readDataLoading} />
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '155.5px', height: '50px' }} />
          )}
          {!readDataLoading ? (
            <div className="search">
              <form className="search-form" onSubmit={(e) => handleOnSubmitSearch(e)} data-cy="search-roles">
                <div className="form-group m-0">
                  <span className="icon icon-search">
                    <img src="/assets/icons/search.svg" alt="Search" title="Search" />
                  </span>
                  <input value={searchForm.query || ''} type="text" data-cy="search-input-field" className="input" placeholder="Search" onChange={(e) => handleOnChangeSearch(e.target.value)} />
                  <span className={`icon icon-close ${!!searchForm.query && searchForm.query.length > 0 ? 'isActive' : ''}`} onClick={() => handleOnClearSearch()}>
                    <img src="/assets/icons/times.svg" alt="Clear" title="Clear" />
                  </span>
                </div>
                <button className="form-submit" data-cy="search-submit-btn">
                  Search
                </button>
              </form>
            </div>
          ) : (
            <LoadingPlaceholder style={{ width: '100%', maxWidth: '850px', height: '50px' }} />
          )}

          <div style={{display:'flex',justifyContent:'flex-end',flex: '1'}}>
            <div>
              <button onClick={() => handleBulkPrintReceipts()} className='btn btn-primary-outline pl-2 pr-2' type='button' disabled={bulkOrders && bulkOrders.length <= 0}>Print Orders</button>
            </div>
          </div>
        </header>
        {/* Main Content */}
        <Main className="section__content section__content relative min-h-table-content">{readDataLoading ? <Loading /> : <Table data={readData.data?.Data} {...tableActionsProps} {...tableFiltersProps} pagination={paginationProps} />}</Main>

        {/* Update Order status */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateOrders) && (
          <Modal {...updateProps} className="sidebar__modal--center order-update-modal">
            <form name="update" ref={updateModalFormRef} onSubmit={(event) => handleOnSubmit({ event: event })} className="ant-form ant-form-horizontal">
              <h2 className="text-center mb-4">Update order status:</h2>
              <h3 className="text-center mb-4">Current status: {updateId.status === 'On Delivery' ? 'Out For Delivery' : updateId.status === 'In Progress' ? 'Fulfillment' : updateId.status}</h3>
              <Radio.Group onChange={onStatusChange} value={selectedStatus}>
                <Space direction="vertical">
                  {radioArray.map((radioItem) => {
                    return (
                      <Radio value={radioItem} key={radioItem}>
                        {radioItem === 'On Delivery' ? 'Out For Delivery' : radioItem === 'In Progress' ? 'Fulfillment' : radioItem}
                        {selectedStatus === 'Canceled' && radioItem === 'Canceled' ? <Input onChange={(e) => setCancelationReason(e.target.value)} className="cancel-reason required" placeholder="Reason for cancelation" /> : null}
                      </Radio>
                    );
                  })}
                </Space>
              </Radio.Group>
              <div className="form-group form-group-modal mb-4">
                {!updateStatusBtnLoader ? (
                  <button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link">
                    <span className="text">Update</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline">
                    <span className="text">Updating...</span>
                  </button>
                )}

                <button
                  data-cy="modal-delete-cancel-btn"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setToggleUpdate(false);
                  }}
                >
                  <span className="text">Cancel</span>
                </button>
              </div>
            </form>
          </Modal>
        )}
        {/* Reschedule Order */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateOrders) && (
          <Modal {...rescheduledProps} className="sidebar__modal--center order-rescheduled-modal">
            <form name="reschedule" ref={rescheduledModalFormRef} onSubmit={(event) => handleOnSubmitRescheduled({ event: event })} className="ant-form ant-form-horizontal">
              <h2 className="text-center mb-4">Reschedule delivery for:</h2>
              <div className="text-center">
                <DatePicker value={rescheduledOrder.moment} allowClear={false} showToday={false} onChange={(m, s) => onSelectDate(m, s, rescheduledOrder.id, rescheduledOrder.status)} disabledDate={disabledDate} />
              </div>
              <div className="form-group form-group-modal mb-4">
                {!updateStatusBtnLoader ? (
                  <button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link" disabled={rescheduledOrder.moment === undefined && true}>
                    <span className="text">Update</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline">
                    <span className="text">Updating...</span>
                  </button>
                )}

                <button
                  data-cy="modal-delete-cancel-btn"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setToggleRescheduled(false);
                  }}
                >
                  <span className="text">Cancel</span>
                </button>
              </div>
            </form>
          </Modal>
        )}

        {/* Delete Reschedule */}
        {getGroceryPermissions(groceryPermissions, PERMISSIONS.updateOrders) && (
          <Modal {...deleteRescheduledProps} className="sidebar__modal--center order-rescheduled-modal">
            <form name="delete-reschedule" ref={deleteRescheduledModalFormRef} onSubmit={(event) => handleOnSubmitRescheduled({ event: event })} className="ant-form ant-form-horizontal">
              <h2 className="text-center mb-4">Remove rescheduling for this order ?</h2>
              <div className="form-group form-group-modal mb-4">
                {!updateStatusBtnLoader ? (
                  <button data-cy="modal-delete-submit-btn" type="submit" className="btn btn-primary-link">
                    <span className="text">Remove</span>
                  </button>
                ) : (
                  <button type="button" className="btn btn-primary-outline">
                    <span className="text">Removing...</span>
                  </button>
                )}

                <button
                  data-cy="modal-delete-cancel-btn"
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setToggleDeleteRescheduled(false);
                  }}
                >
                  <span className="text">Cancel</span>
                </button>
              </div>
            </form>
          </Modal>
        )}
      </Section>

      <div className={`pdf_overlay ${bulkPdfActive ? 'active' : ''}`}>
        <div className='loader'/>
      </div>
    </>
  );
};

export default Orders;
